import styled from "styled-components";
import { PageContainer } from "styles/global.style";
import { bounce, movexy, wave, zoom } from "styles/global.keyframes.style";
import { media } from "styled-bootstrap-grid";

const BannerSection = styled.section`
  background: #f8fcfd;
  text-align: center;
  height: 350px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  ${media.sm`
    height: 550px;
  `}
`;

const BannerInnerContainer = styled(PageContainer)`
  z-index: 100;
`;

const PageTitle = styled.h1`
  font-size: 50px;
  font-weight: 800;
  color: ${(props) => props.theme.titleColor};
  position: relative;
  line-height: 1.2;
  z-index: 2;
  max-width: 820px;
  margin: 32px auto 16px;
`;

const LastUpdate = styled.div``;

const AnimatedRing = styled.svg`
  position: absolute;
  top: -51%;
  left: -15%;
  z-index: 1;
`;

const AnimatedBallContainer = styled.ul`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 0;
  left: 0;
`;

const AnimatedBall = styled.li`
  background: #eff8fd;
  border-radius: 50%;
  position: absolute;

  &:nth-child(1) {
    height: 50px;
    width: 50px;
    top: 37%;
    left: 10%;
    animation: ${zoom} 1.5s infinite alternate;
  }

  &:nth-child(2) {
    height: 70px;
    width: 70px;
    bottom: 22%;
    right: 8%;
    animation: ${bounce} 4s infinite alternate;
  }

  &:nth-child(3) {
    height: 100px;
    width: 100px;
    bottom: 43%;
    right: 22%;
    animation: ${zoom} 1.5s infinite alternate;
  }

  &:nth-child(4) {
    height: 40px;
    width: 40px;
    bottom: 20%;
    right: 32%;
    animation: ${movexy} 1.5s infinite alternate;
  }

  &:nth-child(5) {
    height: 500px;
    width: 500px;
    top: -75%;
    right: 100px;
    animation: ${wave} 3s 0.1s infinite linear;
  }
`;

const ContentSection = styled.section`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(PageContainer)`
  p,
  a {
    font-size: inherit;
  }

  p {
    color: inherit;
    margin: 4px 0 8px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.titleColor};
    margin: 32px 0 8px 0;
    font-weight: 600;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }
`;

export {
  ContentSection,
  BannerSection,
  BannerInnerContainer,
  PageTitle,
  LastUpdate,
  AnimatedRing,
  AnimatedBallContainer,
  AnimatedBall,
  ContentContainer,
};
