import React, { ReactChild } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import {
  AnimatedBall,
  AnimatedBallContainer,
  AnimatedRing,
  BannerInnerContainer,
  BannerSection,
  ContentContainer,
  ContentSection,
  LastUpdate,
  PageTitle,
} from "./index.style";
import SEO, { SEOProps } from "components/core/SEO";

interface ServiceProps {
  title: string;
  subtitle: string;
  children: ReactChild | ReactChild[];
  seoProps: SEOProps;
  location: Location;
}

const Service = ({
  title,
  subtitle,
  children,
  seoProps,
  location,
}: ServiceProps) => {
  return (
    <GlobalWrapper withLayout location={location}>
      <SEO {...seoProps} />
      <BannerSection>
        <BannerInnerContainer>
          <PageTitle>{title}</PageTitle>
          <LastUpdate>{subtitle}</LastUpdate>
        </BannerInnerContainer>

        <AnimatedRing
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="#2fb6fa"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </AnimatedRing>

        <AnimatedBallContainer>
          <AnimatedBall />
          <AnimatedBall />
          <AnimatedBall />
          <AnimatedBall />
          <AnimatedBall />
        </AnimatedBallContainer>
      </BannerSection>

      <ContentSection>
        <ContentContainer>{children}</ContentContainer>
      </ContentSection>
    </GlobalWrapper>
  );
};

export default Service;
